@media all and (min-width: 480px) {
    .Addresses {
        padding: 60px 0;
    }

    .Addresses form {
        margin: 0 auto;
        max-width: 320px;
    }
}
