@media all and (min-width: 480px) {
    .Orders {
        justify-content: center;
        align-items: center;
    }

    .Orders form {
        margin: 0 auto;
        max-width: 320px;
    }
}
